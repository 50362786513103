import React from 'react';
import {Link, useLocation} from "react-router-dom";
import logoLight from "../media/logo-light.svg";
import logoDark from "../media/logo-dark.svg";
import {Home, About, EducationSvg, Projects, Articles, Contact, Research, Resume} from "../media/svg";

const Header = ({setDarkMode}) => {
    const location = useLocation();

    const pages = [
        {
            name: "Home",
            path: "/",
            icon: <Home />,
        },
        {
            name: "Resume",
            path: "/resume",
            icon: <Resume />
        },
        {
            name: "Projects",
            path: "/projects",
            icon: <Projects />
        },
        {
            name: "Research",
            path: "/research",
            icon: <Research />
        },
        // {
        //     name: "Articles",
        //     path: "/articles",
        //     icon: <Articles />
        // },
        // {
        //     name: "Contact",
        //     path: "/contact",
        //     icon: <Contact />
        // }
    ]


    return (
        <div className="z-50">
            <div className="container mx-auto">
                <header className="bg-white dark:bg-black flex justify-between items-center fixed top-0 left-0 w-full lg:static z-[1111111111] lg-sticky">
                    <div className="flex justify-between px-4 lg:px-0 bg-white lg:bg-transparent lg:dark:bg-transparent dark:bg-black ">
                        <div className="flex justify-between items-center space-x-4 lg:my-8 my-5">
                            <a className="text-5xl font-semibold" href="/">
                                <img alt="logo" src={logoLight} className="block dark:hidden h-[48px] lg:h-[128px]"/>
                                <img alt="logo" src={logoDark} className="hidden dark:block h-[48px] lg:h-[128px]"/>
                            </a>
                        </div>
                    </div>

                    <nav className="block px-4 lg:px-0">
                        <ul className="flex my-4 lg:my-12 font-medium items-center">
                            {pages.map((page, index) => (
                                <li key={index} className="mx-2.5">
                                    <Link
                                        className={`gap-2 rounded-md cursor-pointer bg-pure-white dark:bg-dark-gray hover:bg-gradient-to-r from-hrudhai-blue to-hrudhai-purple text-gray-lite dark:hover:text-white hover:text-white font-semibold flex py-2.5 px-2 md:px-4 xl:px-5 items-center transition-all duration-300 ease-in-out linked ${location.pathname === page.path ? 'bg-gradient-to-r text-white dark:text-white' : 'dark:text-gray'}`}
                                        to={page.path}>
                                        <span className={`${location.pathname === page.path ? 'fill-white dark:fill-white' : 'dark:fill-gray'} text-xl fill-gray-lite dark:hover:fill-white hover:fill-white`}>
                                            {page.icon}
                                        </span>
                                        <span className={"hidden lg:block"}>
                                            {page.name}
                                        </span>

                                    </Link>
                                </li>
                            ))}
                            <span className="bg-pure-white w-[32px] h-[32px] md:w-[40px] md:h-[40px] hover:text-white rounded-full flex justify-center items-center text-black hover:bg-hrudhai-blue transition-all duration-300 ease-in-out cursor-pointer ml-2" onClick={() => setDarkMode(mode => !mode)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 lg:w-6 lg:h-6 hidden dark:block dark:fill-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 lg:w-6 lg:h-6 dark:hidden">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                                </svg>
                            </span>
                        </ul>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default Header;