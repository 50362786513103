export const pages = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Resume",
        path: '/resume'
    },
    {
        name: "Projects",
        path: '/projects'
    },
    {
        name: "Research",
        path: '/research'
    },
    // {
    //     name: "Designs",
    //     path: '/designs'
    // },
    // {
    //     name: "Publications",
    //     path: '/publications'
    // },
]