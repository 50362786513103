import React from 'react';
import {GitHub, Mobile, LinkedIn, Location, Mail, Twitter} from "../media/svg";
import {articles} from "../content/articles";
import {work} from "../content/work";
import {projects} from "../content/projects";
import axios from 'axios';
import squirrel from "../media/photography/Atlanta_Squirrel.jpeg";
import butterfly from "../media/photography/Atlanta_Butterfly.jpeg";
import traffic from "../media/photography/Atlanta_Traffic.jpeg";
import atlantic from "../media/photography/Atlantic_Station.jpeg";
import coorg from "../media/photography/Coorg_Falls.jpeg";
import falls from "../media/photography/Kashmir_Falls.jpeg";
import houseboat from "../media/photography/Kashmir_Houseboat.jpeg";
import stars from "../media/photography/Ladakh_Stars.jpeg";

const Home = () => {
    const getResume = () => {
        axios.get("https://fh7k7jtt3kkfljpfafsw323kvm0ffgcg.lambda-url.ap-south-1.on.aws/").then(response => {
            console.log(response.data.url)
            window.open(response.data.url)
        })
    }

    const about = [
        "I'm a Machine Learning Engineer based out of Atlanta, Georgia. I was born and brought up in Bangalore, India where I also used to previously work as a Machine Learning Engineer at krtrimaIQ Cognitive Solutions - an AI startup. My projects include working across a range of domains including Natural Language Processing, Quantitative Finance, and Business Intelligence.",
        "A significant focus of my work has been in the realm of algorithmic trading that leveraged option-based strategies to develop an automated profit-generating portfolio. In parallel, I also acted as a Solutions Architect to build and deploy projects on AWS.",
        "My interests lie in developing intelligent systems that have a positive impact. I'm passionate about turning innovative ideas into tangible solutions. My ultimate goal is to contribute to the creation of intelligent systems that make a difference in diverse industries.",
        "Currently, I'm pursuing my MS in Computer Science at GeorgiaTech, specializing in Machine Learning. I'm working on a project to build recommender systems for movie-streaming platforms using unsupervised, supervised and advanced models. My research lies in the use of classification models that predict market sentiment to make discretionary decisions for algorithmic options trading.",
        "Beyond my professional life, I enjoy photography and traveling.",
    ]

    const expertise = [
        {
            title: "Machine Learning",
            description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
            icon: <Mobile />
        },
        {
            title: "Natural Language Processing",
            description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
            icon: <Mobile />
        },
        {
            title: "Algorithmic Trading",
            description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
            icon: <Mobile />
        },
        {
            title: "Solutions Architect",
            description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
            icon: <Mobile />
        }
    ]

    const photography = [
        {
            image: squirrel,
            caption: "Eastern Gray Squirrel",
            location: "Atlanta"
        },
        {
            image: butterfly,
            caption: "Gulf Fritillary",
            location: "Atlanta"
        },
        {
            image: traffic,
            caption: "Long shutter brake lights",
            location: "Atlanta"
        },
        {
            image: atlantic,
            caption: "Millenium Gate Museum",
            location: "Atlanta"
        },
        {
            image: coorg,
            caption: "Irrupu Falls",
            location: "Coorg"
        },
        {
            image: falls,
            caption: "Aharbal Falls",
            location: "Kashmir"
        },
        {
            image: houseboat,
            caption: "Houseboat on Dal Lake",
            location: "Kashmir"
        },
        {
            image: stars,
            caption: "Night Sky",
            location: "Ladakh"
        }
    ]

    return (
        <div className="col-span-12 lg:col-span-8 ">
            <div className="lg:rounded-2xl bg-white dark:bg-[#111111] px-8 lg:px-0">
                <div data-aos="fade" className="aos-init">
                    <div className="py-12 px-2 sm:px-5 md:px-10 lg:px-14">
                        <h2 className="after-effect after:left-52">
                            About Me
                        </h2>
                        <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center ">
                            <div className="col-span-12 space-y-2.5">
                                <div className="lg:mr-16 flex flex-col gap-3">
                                    {about.map((item, index) => {
                                        return (
                                            <div key={index} className="text-gray-lite dark:text-color-910 leading-7">
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<section className="pb-12 px-2 sm:px-5 md:px-10 lg:px-14">*/}
                    {/*    <h3 className="text-[35px] dark:text-white font-medium pb-5">*/}
                    {/*        My Expertise*/}
                    {/*    </h3>*/}
                    {/*    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">*/}
                    {/*        {expertise.map((item, index) => (*/}
                    {/*            <div key={index} className="dark:bg-transparent">*/}
                    {/*                <img alt="icon" src={item.icon} className="w-10 h-10 object-contain block" />*/}
                    {/*                <div className="space-y-2 break-all">*/}
                    {/*                    <h3 className="dark:text-white text-xl font-semibold">*/}
                    {/*                        {item.title}*/}
                    {/*                    </h3>*/}
                    {/*                    <p className=" leading-8 text-gray-lite dark:text-[#A6A6A6]">*/}
                    {/*                        {item.description}*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <section className="px-2 sm:px-5 md:px-10 lg:px-14 mb-10">
                        <h3 className="text-[35px] dark:text-white font-medium pb-5">
                            I enjoy the occasional photography
                        </h3>
                        <div className="grid gap-8 grid-cols-2 md:grid-cols-4 xl:grid-cols-4 py-8">
                            {photography.map((item, index) => (
                                <div key={index} className="dark:bg-transparent hover:scale-110 text-sm">
                                    <img alt="icon" src={item.image} className="rounded-md w-full object-contain block" />
                                    <div className="break-all text-center pt-2">
                                        <p className="text-gray-lite dark:text-gray break-keep">
                                            {item.caption}
                                        </p>
                                        <p className="text-blue-gray dark:text-blue-gray">
                                            {item.location}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Home;