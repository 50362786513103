import React from 'react';
import {pages} from "../content/pages";
import {Link} from "react-router-dom";

const Footer = () => {
    const d = new Date();
    const year = d.getFullYear();

    return (
        <footer className="mt-32 bg-white dark:bg-black lg:sticky">
            <div className="sm:px-8">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="border-t border-zinc-100 pb-16 pt-10 dark:border-zinc-700/40">
                        <div className="relative px-4 sm:px-8 lg:px-12">
                            <div className="mx-auto max-w-2xl lg:max-w-5xl">
                                <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                                    <div className="flex flex-wrap justify-center gap-x-6 gap-y-1 text-sm font-medium text-zinc-800 dark:text-zinc-200">
                                        {pages.map((page, i) => {
                                            return (
                                                <Link key={i} className={"transition hover:text-purple-500 dark:hover:text-pink-400"} to={page.path}>
                                                    {page.name}
                                                </Link>
                                            )
                                        })}
                                    </div>
                                    <p className="text-sm text-zinc-400 dark:text-zinc-500">
                                        © {year} Hrudhai Rajasekhar. All rights reserved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;