import logo from './logo.svg';
import React, {useState} from 'react';
import './App.css';
import Home from './pages/home';
import Header from "./components/header";
import Articles from "./pages/articles"
import Footer from "./components/footer";
import Projects from "./pages/projects";
import Research from "./pages/research";
import Publications from "./pages/publications";
import Designs from "./pages/designs";
import {Route, Routes} from 'react-router-dom';
import Volunteering from "./pages/volunteering";
import hrudhaiRajasekhar from "./media/hrudhai-rajasekhar.jpg";
import {GitHub, LinkedIn, Location, Mail, Mobile, Twitter} from "./media/svg";
import Resume from "./pages/resume";

function App() {
    const [darkMode, setDarkMode] = useState(true);

    return (
        <div className={darkMode?'dark':''}>
            <div className={`font-poppins font-normal bg-white dark:bg-black text-black dark:text-white`}>
                <Header setDarkMode={setDarkMode} />
                <div className="container mx-auto grid grid-cols-12 md:gap-10 justify-between lg:mt-[220px]">
                    <div className="col-span-12 lg:col-span-4 lg:h-screen lg:sticky top-44 pt-[64px] lg:pt-0">
                        <div className="w-full mb-6 lg:mb-0 mx-auto relative bg-pure-white text-center dark:bg-[#111111] px-6 rounded-[20px] my-[100px] md:mt-[220px] lg:mt-0 ">
                            <img alt="avatar" src={hrudhaiRajasekhar} loading="lazy" style={{color: "transparent"}}
                                 className="w-[120px] h-[120px] lg:w-[240px] lg:h-[240px] absolute left-[50%] transform -translate-x-[50%] drop-shadow-xl mx-auto rounded-[20px] -mt-[70px] lg:-mt-[140px]"
                            />
                            <div className="pt-[40px] lg:pt-[100px] pb-8">
                                <h1 className="mt-6 mb-1 text-3xl lg:text-5xl font-semibold dark:text-white">
                                    Hrudhai Rajasekhar
                                </h1>
                                <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6] ">
                                    Machine Learning Engineer
                                </h3>
                                <div className="flex justify-center space-x-3">
                                    <a href="https://www.linkedin.com/hrudhai-rajasekhar" target="_blank" rel="noopener noreferrer">
                                        <LinkedIn />
                                    </a>
                                    <a href="https://github.com/hrudhai98" target="_blank" rel="noopener noreferrer">
                                        <GitHub />
                                    </a>
                                    <a href="https://x.com/hrudhai98" target="_blank" rel="noopener noreferrer">
                                        <Twitter />
                                    </a>
                                </div>
                                <div className="px-4 lg:px-6 py-2 mx-6 lg:mx-0 rounded-2xl mt-4 bg-[#F3F6F6] dark:bg-[#1D1D1D]">
                                    <div className="flex py-1 lg:py-2.5 gap-3 border-b border-[#E3E3E3] dark:border-[#3D3A3A]">
                                        <span className="flex items-center">
                                            <Location />
                                        </span>
                                        <div className="text-left">
                                            <p className="text-xs text-blue-gray dark:text-gray">
                                                Location
                                            </p>
                                            <p className="dark:text-white break-all">
                                                Atlanta, United States
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex py-1 lg:py-2.5 gap-3">
                                        <span className="flex items-center">
                                            <Mail />
                                        </span>
                                        <div className="text-left">
                                            <p className="text-xs text-blue-gray dark:text-gray">
                                                Email
                                            </p>
                                            <p className="dark:text-white break-all">
                                                <a className="hover:text-[#FA5252] duration-300 transition" href="mailto:hrudhai.rajasekhar@gmail.com">
                                                    hrudhai.rajasekhar@gmail.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mt-6"}>
                                    <div className={"rounded-full w-fit mx-auto bg-gradient-to-br from-hrudhai-blue to-hrudhai-purple"}>
                                        <a href="/images/cv.pdf" download=""
                                           className="m-0.5 inline-flex items-center bg-white dark:bg-black duration-200 transition ease-linear hover:bg-gradient-to-br px-4 lg:px-8 py-2 lg:py-3 lg:text-lg text-black dark:text-white rounded-[35px]">
                                            <span className={"mr-2"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                </svg>
                                            </span>
                                            Download CV
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Routes>
                        <Route path="/" element={<Home />}/>
                        <Route path="/resume" element={<Resume />}/>
                        <Route path="/projects" element={<Projects />}/>
                        <Route path="/research" element={<Research />}/>
                        {/*<Route path={"/articles"}>*/}
                        {/*    <Route path="/articles/:id" element={<Articles/>}/>*/}
                        {/*    <Route path="/articles" element={<Articles/>}/>*/}
                        {/*</Route>*/}
                        {/*<Route path="/courses" element={<Courses />}/>*/}
                        {/*<Route path="/designs" element={<Designs />}/>*/}
                        {/*<Route path="/publications" element={<Publications/>}/>*/}
                        {/*<Route path="/volunteering" element={<Volunteering />}/>*/}
                    </Routes>
                </div>
                <Footer />
            </div>
        </div>

    )
}

export default App;
